import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import commonAPIService from "../../common/js/common-api-service";
export default {
  name: "companyComp",
  props: {
    name: String,
    compCalled: Boolean,
    companyId: String,
    userId: String,
    actionList: Array,
  },
  data: () => ({
    installId: EncryptUtility.localStorageDecrypt("installId"),
    panel: 0,
    outLineText: "",
    projectDisable: false,
    required: [(v) => !!v || "Field is required"],
    // Validation for selected Project because value of 0 is causing an error
    requiredProject: [
      (value) => {
        if (value !== null && value !== "") return true;
        else return "Field is requred.";
      },
    ],
    maxCustRule: [(v) => (v || "").length <= 50 || "Max Length of 50 character", (v) => !!v || "Field is required"],
    maxPhoneRule: [(v) => (v || "").length <= 15 || "Max Length of 15 character", (v) => !!v || "Field is required"],
    maxCountryRule: [(v) => (v || "").length <= 60 || "Max Length of 15 character", (v) => !!v || "Field is required"],
    companyNameRule: [(v) => (v || "").length <= 50 || "Max Length of 50 character", (v) => !!v || "Field is required"],
    zipcode: [(v) => /^\d{5}(-\d{4})?$/.test(v) || "Enter valid Postal"],
    postalCodeNonUS: [(v) => /^[a-zA-Z0-9 ]+$/.test(v) || "Invalid Postal Code"],
    phoneRules: [(v) => /^(()?\d{3}())?(-|\s)?\d{3}(-|\s)?\d{4}$/.test(v) || "Enter valid Number"],
    phoneRulesWithNo: [(v) => !v || /^(()?\d{3}())?(-|\s)?\d{3}(-|\s)?\d{4}$/.test(v) || "Enter valid Number"],
    emailRules: [(v) => !v || /.+@.+\..+/.test(v) || "Enter valid Email"],
    valid: true,
    edit: false,
    companyId: "",
    companyName: "",
    address1: "",
    address2: "",
    firstName: "",
    lastName: "",
    phone1: "",
    phone2: "",
    deleteCompanyDialog: false,
    validate: true,
    cell: "",
    fax: "",
    email: "",
    title: "",
    showUpdate:false,
    city: "",
    selectedProject: "",
    selectedState: "",
    selectedCountry: "USA",
    businessList: [],
    companyTypeList: [
      { companyType: "OEM", typeId: 1, disabled: false },
      { companyType: "ODM", typeId: 2, disabled: false },
      { companyType: "Contact", typeId: 3, disabled: false },
      { companyType: "Bill-To", typeId: 4, disabled: false },
      { companyType: "Project Owner", typeId: 5, disabled: false },
      { companyType: "Warehouse Location", typeId: 6, disabled: false },
      { companyType: "Depot", typeId: 7, disabled: false },
      {
        companyType: "Forward Stocking Location (FSL)",
        typeId: 8,
        disabled: false,
      },
      { companyType: "Warehouse Owner", typeId: 9, disabled: false },
      { companyType: "Return-To Location", typeId: 10, disabled: false },
    ],
    projectOwner: false,
    odm: false,
    oem: false,
    whOwner: false,
    custno: "",
    companyType: [],
    wh_Local: false,
    contact: false,
    bill2: false,
    depot: false,
    fsl: false,
    so_retloc: false,
    active: true,
    Projkey: "",
    projectData: [],
    countryData: [],
    stateData: [],
    stateDataList: [],
    businessId: "",
    postal: "",
    showStateDrop: false,
    pass: 0,
  }),
  mounted() {
    this.getProjectList();
    this.getCountryStateList();
    //to popluate the values in update
    if (this.name != "newCompany") {
      this.projectDisable = true;
      this.getCompanyDetails(this.companyId);
      if (this.oem == true) this.companyType.push(1);
      if (this.odm == true) this.companyType.push(2);
      if (this.contact == true) this.companyType.push(3);
      if (this.bill2 == true) this.companyType.push(4);
      if (this.project_owner == true) this.companyType.push(5);
      if (this.wh_Loca == true) this.companyType.push(6);
      if (this.depot == true) this.companyType.push(7);
      if (this.fsl == true) this.companyType.push(8);
      if (this.wh_owner == true) this.companyType.push(9);
      if (this.so_retloc == true) this.companyType.push(10);
      this.active = this.active == "1" ? true : false;
    } else {
      this.active = true;
    }
  },
  methods: {
        //to check if its a number of not
        isNumber(event,loc){
          if(loc=="number"){
          let value= Utility.isNumber(event)
          return value}
          else{
            let value= Utility.isNumberWithHypen(event)
            return value
          }
        },
    // Outline color
    disableTextbox() {
      if (this.outLineText == "success" && this.custno?.length > 2) {
        this.validate = false;
      }
    },
    //Company Details API
    getCompanyDetails(comp_id) {
      this.exportDialog = false;
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      let searchObject = {
        UserId: 1,
        search_key: "",
        comp_id: comp_id,
        proj_keys: [],
        bu_keys: [],
        strActive: 0,
        PageNumber: "1",
        Pass: "3",
      };
      let searchJson = { json: JSON.stringify(searchObject) };
      this.axios
        .post("cp/company_list", searchJson)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            let res = JSON.parse(response.data.body.message);
            res.map((ele) => {
              this.selectedCountry = ele.country;
              this.countrySelected();
              this.custno = ele.custno;
              this.companyName = ele.Company;
              this.address1 = ele.address1;
              this.address2 = ele.address2;
              this.city = ele.city;
              this.selectedState = ele.state;
              this.postal = ele.zip;
              this.selectedProject = ele.proj_key;
              this.active = ele.active;
              this.firstName = ele.first;
              this.lastName = ele.last;
              this.title = ele.title;
              this.businessId = ele.bu_key;
              this.email = ele.email;
              this.phone1 = ele.phone1;
              this.phone2 = ele.phone2;
              this.cell = ele.cell;
              this.fax = ele.fax;
              if (ele.oem == true) this.companyType.push(1);
              if (ele.odm == true) this.companyType.push(2);
              if (ele.contact == true) this.companyType.push(3);
              if (ele.bill2 == true) this.companyType.push(4);
              if (ele.project_owner == true) this.companyType.push(5);
              if (ele.wh_Loca == true) this.companyType.push(6);
              if (ele.depot == true) this.companyType.push(7);
              if (ele.fsl == true) this.companyType.push(8);
              if (ele.wh_owner == true) this.companyType.push(9);
              if (ele.so_retloc == true) this.companyType.push(10);
            });
            let businessObj = {
              UserId: this.userId,
              ProjKey: this.selectedProject,
              BuKey: 0, // Do not Delete
              IncludeEmptyBU: 1, // Do not Delete
              WithinSameLocation: 0, // Do not Delete
              loca_key: 0,
            };
            this.getBussinessData(businessObj);
            this.exportDisable = false;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          this.companyList = [];
          this.exportDisable = true;
          this.totalRecords = 0;
          this.pageCount = 0;
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    // To check if the user has access access to that action or not
    actionSetter(action) {
      if (this.actionList !== null && this.actionList?.length > 0) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },
    // to enable or disable company Type
    onSelectCompany(companyType) {
      if (companyType.includes(7)) {
        this.companyTypeList[7].disabled = true;
      } else {
        this.companyTypeList[7].disabled = false;
      }
      if (companyType.includes(8)) {
        this.companyTypeList[6].disabled = true;
      } else {
        this.companyTypeList[6].disabled = false;
      }
    },
    // TO check if the company id is unique or not
    companyIdCheck(custno) {
      this.custno = this.custno?.toUpperCase();
      if (custno?.length > 2) {
        if (this.$refs.companyCheckForm.validate()) {
          let id = custno;
          let userid = this.userId;
          this.axios
            .get(`/cp/company_validate?custno=${id}&userid=${userid}`)
            .then((response) => {
              if (response.status == 200) {
                this.outLineText = "success";
              } else {
                this.validate = true;
                var Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                this.validate = true;
              }
            })
            .catch((error) => {
              this.outLineText = "error";
              this.validate = true;
              var Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
            });
        }
      } else {
        this.outLineText == "";
        this.validate = true;
      }
    },
    //API Call to delete the company Data
    deleteCompany() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      let deleteCompany = {
        CompanyId: this.companyId,
        UserId: this.userId,
      };
      this.axios
        .post("/cp/company_delete", deleteCompany)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$emit("companyUpdate");
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.message,
            };
            this.deleteCompanyDialog = false;
            this.onClickCancel();
            this.$store.commit("ConfigModule/Alert", Alert);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.deleteMenuDialog = false;
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    // To open the delete Dialog Box
    deleteDialog() {
      this.deleteCompanyDialog = true;
    },
    // to get the list of projects
    async getProjectList() {
      this.projectData = [];
      let data = await commonAPIService.getProjectAllComp("get", this.userId);
      if (data.message !== "NA") {
        this.projectData = data.ProjectInfo;
      }
    },
    // to get the state based on the country only US state's drop is loaded for rest of the country its a textbox
    getCountryStateList() {
      this.pass = 1;
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/cp/country_state_details_new?user_id=${this.userId}&country_key=${this.selectedCountry}&pass=${this.pass}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = response.data.body.message;
            this.countryData = JSON.parse(responseData).CountryInfo;
            // to make the default country as USA
            if (this.name == "newCompany") {
              this.selectedCountry = "USA";
              this.countrySelected();
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Country Selected
    async countrySelected() {
      this.pass = 2;
      this.selectedState = "";
      this.postal = "";
      this.$refs.companyAddForm.resetValidation();
      let data1 = await commonAPIService.countryListNew(this.userId, this.selectedCountry, this.pass, "get");
      this.stateData = data1.StateInfo;
      this.stateDataList = data1.StateInfo;
      if (this.stateData.length !== 0) {
        this.showStateDrop = true;
      } else {
        this.showStateDrop = false;
      }
    },
    //Add Company Information
    onClickAdd() {
      if (this.$refs.companyAddForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.oem = false;
        this.odm = false;
        this.contact = false;
        this.bill2 = false;
        this.projectOwner = false;
        this.wh_Local = false;
        this.depot = false;
        this.fsl = false;
        this.whOwner = false;
        this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        this.companyType.forEach((element) => {
          switch (element) {
            case 1:
              this.oem = true;
              break;
            case 2:
              this.odm = true;
              break;
            case 3:
              this.contact = true;
              break;
            case 4:
              this.bill2 = true;
              break;
            case 5:
              this.projectOwner = true;
              break;
            case 6:
              this.wh_Local = true;
              break;
            case 7:
              this.depot = true;
              break;
            case 8:
              this.fsl = true;
              break;
            case 9:
              this.whOwner = true;
              break;
            case 10:
              this.so_retloc = true;
              break;
          }
        });
        let companyData = {
          Company_Id: 0,
          CustNo: this.custno,
          Company: this.companyName,
          First: this.firstName,
          Last: this.lastName,
          Title: this.title,
          Email: this.email == null ? "" : this.email,
          Address1: this.address1,
          Address2: this.address2 == null ? "" : this.address2,
          City: this.city,
          State: this.selectedState,
          Zip: this.postal,
          Country: this.selectedCountry,
          Phone1: this.phone1,
          Phone2: this.phone2,
          Cell: this.cell == null ? "" : this.cell,
          Fax: this.fax,
          ProjectOwner: this.projectOwner,
          ODM: this.odm,
          OEM: this.oem,
          WhOwner: this.whOwner,
          Wh_Local: this.wh_Local,
          Contact: this.contact,
          Bill2: this.bill2,
          Depot: this.depot,
          so_retloc: this.so_retloc,
          FSL: this.fsl,
          Active: true,
          Projkey: this.selectedProject,
          Bukey: this.businessId,
          userID: this.userId,
          installid: this.installId,
        };
        {
          this.axios
            .post("/cp/company_create", companyData)
            .then((response) => {
              if (response.status == 200 || response.status == 204) {
                LoaderDialog.visible = false;
                this.$emit("companyUpdate");
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "success",
                  isShow: true,
                  message: response.data.message,
                };
                this.onClickCancel();
                this.$store.commit("ConfigModule/Alert", Alert);
              } else {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: response.data.message,
                };
                this.$store.commit("ConfigModule/Alert", Alert);
              }
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            })
            .catch((error) => {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: Utility.apiError(error),
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.errorMessage = error.message;
            });
        }
      }
    },
    //Load the States based on country change
    // changeCountry(event) {
    //   console.log(this.allowEditstateDat, event);
    //   this.stateDataList = this.stateData.filter((obj) => obj.countrykey === event);
    //   console.log(this.stateDataList);
    // },
    async getBussinessData(businessObject) {
      let businessData = await commonAPIService.postBusinessData("post", businessObject);
      this.businessList = businessData.map((x) => ({
        bu_id: x.Bu_id,
        business: x.Business,
        bu_payterm: x.pterms,
      }));
    },
    onChangeProject(projectId) {
      if (projectId !== undefined && projectId !== null) {
        if (projectId == 0) {
          this.businessId = 0;
          this.disableBusiness = true;
        } else {
          this.businessId = "";
          this.disableBusiness = false;
        }
        this.projectDisable = true;
        this.projectArray = [];
        this.projectArray.push(projectId);
        let businessObj = {
          UserId: this.userId,
          ProjKey: projectId,
          BuKey: 0, // Do not Delete
          IncludeEmptyBU: 1, // Do not Delete
          WithinSameLocation: 0, // Do not Delete
          loca_key: 0,
        };
        this.getBussinessData(businessObj);
      }
    },
    //Search Cancel
    onClickCancel() {
      this.compCalled = false;
      this.$emit("hide");
      this.name = "";
      this.deleteCompanyDialog = false;
      this.companyId = "";
      this.custno = "";
      this.companyName = "";
      this.firstName = "";
      this.companyType = "";
      this.lastName = "";
      this.title = "";
      this.email = "";
      this.address1 = "";
      this.address2 = "";
      this.city = "";
      this.selectedState = "";
      this.postal = "";
      this.selectedCountry = "";
      this.phone1 = "";
      this.phone2 = "";
      this.cell = "";
      this.fax = "";
      this.projectOwner = false;
      this.odm = false;
      this.oem = false;
      this.whOwner = false;
      this.wh_Local = false;
      this.contact = false;
      this.bill2 = false;
      this.depot = false;
      this.so_retloc = false;
      this.fsl = false;
      this.active = false;
      this.selectedProject = "";
    },
    //check if we can allow edit
    allowEdit() {
      this.showUpdate=true
      this.edit = true;
    },
    //Edit Company Infomration
    onClickEdit() {
      if (this.$refs.companyAddForm.validate()) {
        let LoaderDialog = {
          visible: true,
          title: "Please Wait...",
        };
        this.oem = false;
        this.odm = false;
        this.contact = false;
        this.bill2 = false;
        this.projectOwner = false;
        this.wh_Local = false;
        this.depot = false;
        this.fsl = false;
        this.whOwner = false;
        this.so_retloc = false;
        this.companyType.forEach((element) => {
          switch (element) {
            case 1:
              this.oem = true;
              break;
            case 2:
              this.odm = true;
              break;
            case 3:
              this.contact = true;
              break;
            case 4:
              this.bill2 = true;
              break;
            case 5:
              this.projectOwner = true;
              break;
            case 6:
              this.wh_Local = true;
              break;
            case 7:
              this.depot = true;
              break;
            case 8:
              this.fsl = true;
              break;
            case 9:
              this.whOwner = true;
              break;
            case 10:
              this.so_retloc = true;
              break;
          }
        });
        let companyData = {
          Company_Id: this.companyId == undefined ? 0 : this.companyId,
          CustNo: this.custno == undefined ? "" : this.custno,
          Company: this.companyName == undefined ? "" : this.companyName,
          First: this.firstName == undefined ? "" : this.firstName,
          Last: this.lastName == undefined ? "" : this.lastName,
          Title: this.title == undefined ? "" : this.title,
          Email: this.email == undefined ? "" : this.email,
          Address1: this.address1 == undefined ? "" : this.address1,
          Address2: this.address2 == undefined ? "" : this.address2,
          City: this.city == undefined ? "" : this.city,
          State: this.selectedState == undefined ? "" : this.selectedState,
          Zip: this.postal == undefined ? "" : this.postal,
          Country: this.selectedCountry == undefined ? "" : this.selectedCountry,
          Phone1: this.phone1 == undefined ? "" : this.phone1,
          Phone2: this.phone2 == undefined ? "" : this.phone2,
          Cell: this.cell == undefined ? "" : this.cell,
          Fax: this.fax == undefined ? "" : this.fax,
          ProjectOwner: this.projectOwner,
          ODM: this.odm,
          OEM: this.oem,
          WhOwner: this.whOwner,
          Wh_Local: this.wh_Local,
          Contact: this.contact,
          Bill2: this.bill2,
          Depot: this.depot,
          FSL: this.fsl,
          so_retloc: this.so_retloc,
          Active: this.active == "1" ? true : false,
          Projkey: this.selectedProject == null ? 0 : this.selectedProject,
          userID: this.userId,
          BuKey: this.businessId == undefined ? 0 : this.businessId,
        };
        this.axios
          .post("/cp/company_update", companyData)
          .then((response) => {
            if (response.status == 200 || response.status == 204) {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              this.$emit("companyUpdate");
              let Alert = {
                type: "success",
                isShow: true,
                message: response.data.body.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
              this.$emit("companyChange");
              this.onClickCancel();
            } else {
              LoaderDialog.visible = false;
              this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              let Alert = {
                type: "error",
                isShow: true,
                message: response.data.message,
              };
              this.$store.commit("ConfigModule/Alert", Alert);
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          })
          .catch((error) => {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: Utility.apiError(error),
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.errorMessage = error.message;
          });
      }
    },
    //Close Dialog
    closeDialog() {
      this.compCalled = false;
      this.outLineText = "";
      this.$emit("hide");
      this.name = "";
      this.deleteCompanyDialog = false;
      this.companyId = "";
      this.custno = "";
      this.companyName = "";
      this.firstName = "";
      this.companyType = "";
      this.lastName = "";
      this.title = "";
      this.email = "";
      this.address1 = "";
      this.address2 = "";
      this.city = "";
      this.selectedState = "";
      this.postal = "";
      this.selectedCountry = "";
      this.phone1 = "";
      this.phone2 = "";
      this.cell = "";
      this.fax = "";
      this.projectOwner = false;
      this.odm = false;
      this.oem = false;
      this.whOwner = false;
      this.wh_Local = false;
      this.contact = false;
      this.bill2 = false;
      this.depot = false;
      this.so_retloc = false;
      this.fsl = false;
      this.active = false;
      this.selectedProject = "";
    },
    //To check if its a number of not
    postalCodeFormat(event, num) {
      this.postal = Utility.formatPostalCode(event, num);
    },
  },
};
